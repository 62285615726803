import { Components, Theme } from "@mui/material";

export const MuiPaper: Components<Theme>["MuiPaper"] = {
  styleOverrides: {
    root: {
      boxShadow: "none",
      "& .MuiAccordion-root": {
        backgroundColor: "transparent",
      },
    },
  },
};
