import { Components, Theme } from "@mui/material";
import { Children, isValidElement } from "react";

export const MuiInputLabel: Components<Theme>["MuiInputLabel"] = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: ({ theme, children }) => {
      const show_label = Children.toArray(children).every((child) => {
        if (!isValidElement(child)) return true;
        return child.props.label !== false;
      });
      return theme.unstable_sx({
        position: "relative",
        transform: "none",
        fontSize: "16px",
        marginBottom: show_label ? "12px" : 0,
        color: "grey.700",
        "&.Mui-focused": {
          color: "grey.700",
        },
      });
    },
  },
};
