import { Theme } from "@mui/material";
import { Components } from "@react-admin/ra-enterprise";

export const RaTopToolbar: Components["RaTopToolbar"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        gap: "12px",
        boxShadow: "none",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
          color: "grey.400",
          width: "13px",
          height: "13px",
        },
        [theme.breakpoints.up("md")]: {
          paddingX: 3,
          paddingY: 0,
        },
      }),
  },
};
