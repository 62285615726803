import { Components } from "@react-admin/ra-enterprise";
import { Theme } from "@mui/material";

export const RaTreeInput: Components["RaTreeInput"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        "& .rc-tree-treenode-selected .rc-tree-node-selected": {
          backgroundColor: "blue.200",
        },
      }),
  },
};
