export const yellow = {
  50: "#FFFEEC",
  100: "#FFF9CF",
  200: "#FFF19F",
  300: "#FFE86F",
  400: "#FFDE4B",
  500: "#FFCF0F",
  600: "#DBAD0A",
  700: "#B78D07",
  800: "#7B5C03",
  900: "#4F3903",
};
