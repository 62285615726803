import { Components } from "@react-admin/ra-enterprise";

export const RaDatagridHeaderCell: Components["RaDatagridHeaderCell"] = {
  styleOverrides: {
    root: {
      "&.column-id:nth-of-type(1), &.column-featured": {
        width: "1%",
      },
    },
  },
};
