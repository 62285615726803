import { Components, Theme } from "@mui/material";

export const MuiCardContent: Components<Theme>["MuiCardContent"] = {
  variants: [
    {
      // @ts-ignore
      props: { variant: "bordered" },
      style: ({ theme }) =>
        theme.unstable_sx({
          // margin: "0px",
          // padding: "0px",
          borderRadius: "12px",
          minWidth: "100%",
          display: "flex",
          justifyContent: "space-between",
        }),
    },
  ],
  styleOverrides: {
    root: {
      padding: "0px",
      "&:last-child": {
        paddingBottom: "0px",
      },
      // width: "380px",
      minHeight: "fit-content",

      "& .user": {
        margin: "24px",
        width: "207px",
        minHeight: "fit-content",
      },
      borderRadius: "12px",
    },
  },
};
