import { Theme } from "@mui/material";
import { Components } from "@react-admin/ra-enterprise";
import { ButtonProps } from "react-admin";

export const RaButton: Components["RaButton"] = {
  styleOverrides: {
    root: ({ theme, variant, ...rest }: { theme: Theme } & ButtonProps) => {
      if (!variant || variant === "outlined") {
        return theme.unstable_sx({
          borderRadius: "32px",
          boxShadow: "none",
          minWidth: "min-content",
          padding: "12px 20px",
          color: "grey.900",
          border: `1px solid`,
          borderColor: "grey.200",
          backgroundColor: "white",
          "& .MuiButton-startIcon": {
            // color: `${grey[400]}`,
          },
          ":focus, :hover, :disabled": {
            backgroundColor: "grey.50",
          },
          ":focus, :hover": {
            borderColor: "grey.200",
          },
          ":active": {
            backgroundColor: "white",
            border: `1px solid`,
            borderColor: "blue.500",
          },
          ":disabled": {
            borderColor: "transparent",
          },
        });
      }
    },
  },
};
