import { Components, Theme } from "@mui/material";
export const MuiDialogActions: Components<Theme>["MuiDialogActions"] = {
  styleOverrides: {
    // root: ({ theme }) =>
    //   theme.unstable_sx({
    //     "& .MuiButton-root": {
    //       borderRadius: "32px",
    //       marginBottom: "20px",
    //       boxShadow: "none",
    //       minWidth: "min-content",
    //       padding: "13px 30px",
    //       color: "grey.900",
    //       fontFamily: "General sans",
    //       fontWeight: 400,
    //       fontSize: "14px",
    //       lineHeight: "14px",
    //       letterSpacing: "0",
    //       border: `1px solid`,
    //       borderColor: "grey.200",
    //       backgroundColor: "white",
    //       "& .MuiButton-startIcon": {
    //         display: "none",
    //         "& .MuiSvgIcon-root": {
    //           width: "16px",
    //           height: "16px",
    //         },
    //       },
    //       ":focus, :hover, :disabled": {
    //         backgroundColor: "grey.50",
    //       },
    //       ":focus, :hover": {
    //         borderColor: "grey.200",
    //       },
    //       ":active": {
    //         backgroundColor: "white",
    //         border: `1px solid`,
    //         borderColor: "blue.500",
    //       },
    //       ":disabled": {
    //         color: "grey.50",
    //         borderColor: "transparent",
    //       },
    //       "& .MuiButton-root": {},
    //     },
    //     "& .RaConfirm-confirmPrimary": {
    //       boxShadow: "none",
    //       borderRadius: "32px",
    //       minWidth: "min-content",
    //       padding: "14px 30px",
    //       fontFamily: "General sans",
    //       fontWeight: 400,
    //       fontSize: "14px",
    //       lineHeight: "14px",
    //       letterSpacing: "0",
    //       border: `1px solid`,
    //       borderColor: "grey.200",
    //       color: `white`,
    //       backgroundColor: "red.700",
    //       ":focus, :hover": {
    //         backgroundColor: "red.900",
    //       },
    //       ":active": {
    //         backgroundColor: "red.900",
    //       },
    //       ":disabled": {
    //         backgroundColor: "grey.50",
    //         color: "grey.50",
    //         borderColor: "transparent",
    //       },
    //       "& .MuiButton-startIcon": {
    //         display: "inherit",
    //         color: `white`,
    //         "& .MuiSvgIcon-root": {
    //           width: "16px",
    //           height: "16px",
    //         },
    //       },
    //     },
    //   }),
  },
};
