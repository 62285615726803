import { Components } from "@react-admin/ra-enterprise";

export const RaSelectArrayInput: Components["RaSelectArrayInput"] = {
  styleOverrides: {
    root: {
      "& .RaSelectArrayInput-chip": {
        margin: "0px 2px",
      },
    },
  },
};
