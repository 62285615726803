import { Components, Theme } from "@mui/material";
import { grey } from "~/theme/colors";

export const MuiCheckbox: Components<Theme>["MuiCheckbox"] = {
  //  MuiCheckbox-root-MuiTab-root.Mui-selected
  styleOverrides: {
    root: {
      color: grey[300],
      height: "22px",
      width: "22px",
    },
  },
};
