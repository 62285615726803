export const green = {
  50: "#F6FFF0",
  100: "#E6FBD9",
  200: "#C9F8B4",
  300: "#A0EC8A",
  400: "#79D969",
  500: "#44C13C",
  600: "#2BA52E",
  700: "#1E8A29",
  800: "#0F5B1D",
  900: "#073E16",
};
