import { defaultLightTheme } from "@react-admin/ra-enterprise";
import { theme as navigationTheme } from "@react-admin/ra-navigation";
import { merge } from "lodash";
import { blue, grey, green, red, white, yellow } from "./colors";
import { sharedTheme } from "./sharedTheme";
import { ThemeOptions } from "./types";

const customLightTheme: ThemeOptions = {
  palette: {
    primary: {
      main: blue[400],
      light: blue[200],
      dark: blue[600],
      contrastText: white,
    },
    success: {
      main: green[700],
      light: green[600],
      dark: green[900],
      contrastText: grey[900],
    },
    info: {
      main: blue[700],
      light: blue[600],
      dark: blue[900],
      contrastText: white,
    },
    secondary: {
      main: grey[100],
      light: grey[50],
      dark: grey[300],
      contrastText: grey[900],
    },
    error: {
      main: red[700],
      light: red[200],
      dark: red[900],
      contrastText: white,
    },
    warning: {
      main: yellow[500],
      light: yellow[300],
      dark: yellow[700],
      contrastText: grey[900],
    },
    background: {
      paper: grey[50],
      default: white,
    },
    grey,
    green,
    blue,
    red,
    yellow,
  },
};

export const lightTheme: ThemeOptions = merge(
  {},
  navigationTheme,
  defaultLightTheme,
  sharedTheme,
  customLightTheme
);
