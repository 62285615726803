import { Components, Theme } from "@mui/material";

export const MuiMenu: Components<Theme>["MuiMenu"] = {
  styleOverrides: {
    paper: ({ theme }) =>
      theme.unstable_sx({ border: 1, borderColor: "grey.200" }),
    list: ({ theme }) =>
      theme.unstable_sx({
        backgroundColor: "common.white",
        //  border: "1px solid red"
      }),
  },
};
