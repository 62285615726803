import { Components } from "@react-admin/ra-enterprise";

export const RaBulkActionsToolbar: Components["RaBulkActionsToolbar"] = {
  styleOverrides: {
    root: {
      "& .MuiToolbar-root": {
        backgroundColor: "white",
        boxShadow: "none",
        display: "flex",
        height: "70px",
        marginTop: "-10px",
      },
      "& .RaBulkActionsToolbar-collapsed": { display: "none" },
      "& .RaBulkActionsToolbar-title": {
        color: "black",
        fontSize: "16px",
        backgroundColor: "transparent",
        height: "inherit",
        alignItems: "center",
        h6: {
          fontSize: "16px",
        },
      },
      "& .RaBulkDeleteWithUndoButton": {},
    },
  },
};
