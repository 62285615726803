export const red = {
  50: "#FFF4EC",
  100: "#FFE8D7",
  200: "#FFCCB0",
  300: "#FFA988",
  400: "#FF886B",
  500: "#FF513A",
  600: "#DB302A",
  700: "#B71D23",
  800: "#931222",
  900: "#7A0B21",
};
