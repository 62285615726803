import { Components } from "@react-admin/ra-enterprise";

export const RaSearchInput: Components["RaSearchInput"] = {
  styleOverrides: {
    root: {
      width: "100%",
      "& .MuiInputLabel-root": {
        display: "none",
      },
    },
  },
};
