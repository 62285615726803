import { Components, Theme } from "@mui/material";

export const MuiContainer: Components<Theme>["MuiContainer"] = {
  styleOverrides: {
    root: {
      "@media (min-width: 600px)": {
        padding: 0,
      },
    },
  },
};
