import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions = {
  fontFamily: "General Sans",
  fontWeightMedium: 400,
  fontWeightBold: 600,
  h1: {
    fontFamily: "General Sans",
    fontSize: "42px",
    fontWeight: 600,
    lineHeight: "50px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  h2: {
    fontFamily: "General Sans",
    fontSize: "36px",
    fontWeight: 600,
    lineHeight: "43px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  h3: {
    fontFamily: "General Sans",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "34px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  h4: {
    fontFamily: "General Sans",
    fontSize: "24px",
    fontWeight: 600,
    // lineHeight: "36px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  h5: {
    fontFamily: "General Sans",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "27px",
    letterSpacing: "0.01em",
    // textAlign: "start",
  },
  h6: {
    fontFamily: "General Sans",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "21px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  button: {
    textTransform: "capitalize",
    fontFamily: "General sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "0",
    textAlign: "left",
  },
  body1: {
    //styleName: Body/Small;
    fontFamily: "General Sans",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  body2: {
    //styleName: Body/Medium;
    fontFamily: "General Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  caption: {
    //styleName: Body/Large;
    fontFamily: "General Sans",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  subtitle1: {
    //styleName: Body/Large;
    fontFamily: "General Sans",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "86px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  subtitle2: {
    //styleName: Display/Medium;
    fontFamily: "General Sans",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "77px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
};
