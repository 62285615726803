import { Components } from "@react-admin/ra-enterprise";

export const RaFilterForm: Components["RaFilterForm"] = {
  styleOverrides: {
    root: {
      alignItems: "center",
      "& .MuiFormLabel-root": { display: "none" },
      "& .MuiAutocomplete-inputRoot .MuiInputBase-input": {
        // width: "auto",
      },
    },
  },
};
