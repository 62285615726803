import { Components, Theme } from "@mui/material";

export const MuiButton: Components<Theme>["MuiButton"] = {
  variants: [
    {
      props: { variant: "outlined" },
      //@ts-ignore mui does not correctly type ownerState in variants. But it does work.
      style: ({ ownerState, theme }) => {
        return theme.unstable_sx({
          fontWeight: 500,
          color: "grey.900",
          borderColor: "grey.200",
          "& .MuiButton-startIcon": {
            color: "grey.400",
          },
          ":focus, :hover, :disabled": {
            backgroundColor: "background.paper",
          },
          ":focus, :hover": {
            borderColor: "grey.200",
            "& .MuiButton-startIcon": {
              color: "grey.900",
            },
          },
          ":focus": {
            borderColor: `${ownerState.color}.main`,
          },
          ":disabled": {
            color: "grey.300",
            backgroundColor: "white",
            borderColor: "grey.300",
          },
          "& .MuiTouchRipple-child": {
            backgroundColor: `${ownerState.color}.light`,
          },
        });
      },
    },
    {
      props: { variant: "dropdown" },
      style: ({ theme }) =>
        theme.unstable_sx({
          backgroundColor: "transparent",
          color: "grey.800",
        }),
    },
  ],

  styleOverrides: {
    root: {
      borderRadius: "32px",
      boxShadow: "none",
      minWidth: "min-content",
      padding: "12px 20px",
    },
  },
};
