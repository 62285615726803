import { Components, Theme } from "@mui/material";

export const MuiDialog: Components<Theme>["MuiDialog"] = {
  styleOverrides: {
    root: {
      "& 	.MuiDialog-paper": {
        // maxWidth: "386px",
        // display: "flex",
        // alignItems: "center",
        borderRadius: "12px",
      },
      "& .MuiDialogTitle-root": {
        fontFamily: " General Sans",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "27px",
        letterSpacing: " 0em",
      },
    },
  },
};
