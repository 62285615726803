import { Components } from "@react-admin/ra-enterprise";
import { Theme } from "@mui/material";

export const RaShow: Components["RaShow"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        backgroundColor: "transparent",
        "& .RaShow-card": {
          margin: "32px",
          padding: "32px 24px",
          backgroundColor: "white",
        },
      }),
  },
};
