import { Components, Theme } from "@mui/material";

export const MuiSwitch: Components<Theme>["MuiSwitch"] = {
  styleOverrides: {
    root: {
      width: 32,
      height: 20,
      borderRadius: 50,
      padding: 0,
      margin: "0px 12px 0px 16px",
      display: "flex",
      "&:active": {
        "& .MuiSwitch-thumb": {
          width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
          transform: "translateX(9px)",
        },
      },
      "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
          transform: "translateX(12px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "#3182CE",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 16,
        height: 16,
        borderRadius: 50,
      },
      "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 0.3,
        backgroundColor: "#3182CE",

        boxSizing: "border-box",
      },
    },
  },
};
