import { Components } from "@react-admin/ra-enterprise";

export const RaNotification: Components["RaNotification"] = {
  styleOverrides: {
    root: ({ theme }: { theme: any }) =>
      theme.unstable_sx({
        "& .MuiSnackbarContent-root": {
          borderRadius: "6px",
          borderTop: "4px solid ", // default is "info" snackbar
          borderColor: "blue.700",
          backgroundColor: "blue.200",
        },
        "& .MuiSnackbarContent-message": {
          fontFamily: "General Sans",
          fontSize: "14px",
          fontWeight: 400,
          letterSpacing: 0,
          textAlign: "left",
          lineHeight: "150%",
          color: "common.black",
        },
        "& .RaNotification-warning": {
          borderColor: "yellow.700",
          backgroundColor: "yellow.200",
        },
        "& .RaNotification-error": {
          borderColor: "red.700",
          backgroundColor: "red.200",
        },
        "& .RaNotification-success": {
          borderColor: "green.700",
          backgroundColor: "green.200",
        },
      }),
  },
};
