import { Components } from "@react-admin/ra-enterprise";

export const RaToolbar: Components["RaToolbar"] = {
  styleOverrides: {
    root: {
      backgroundColor: "white",
      boxShadow: "none",

      // margin: "0px 32px 40px 32px",
      borderRadius: "16px",
      padding: "20px",
    },
  },
};
