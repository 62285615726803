import { Components, Theme } from "@mui/material";

export const MuiFormHelperText: Components<Theme>["MuiFormHelperText"] = {
  styleOverrides: {
    root: {
      margin: 0,
      fontSize: 14,
      marginTop: 0,
    },
  },
};
