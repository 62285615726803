import { Theme } from "@mui/material";
import { Components } from "@react-admin/ra-enterprise";

export const RaSimpleForm: Components["RaSimpleForm"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        // backgroundColor: "grey.50",
        marginTop: 0,
        "& .MuiTabs-root": {
          backgroundColor: "white",
        },
        "& .MuiCardContent-root": {
          width: "95%",
          padding: "32px",
          backgroundColor: "white",
          margin: "32px",
          marginBottom: "0px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        "& .MuiToolbar-root": {
          marginLeft: "32px",
          marginRight: "32px",
          marginTop: "0px",
          marginBottom: "32px",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          padding: "32px",
        },
      }),
  },
};
