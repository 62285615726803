import { Components } from "@react-admin/ra-enterprise";
import { Theme } from "@mui/material/styles";

export const RaDatagrid: Components["RaDatagrid"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        border: "none",
        width: "-webkit-fill-available",
        "& .RaDatagrid-checkbox": {
          color: "red",
        },
        "& .RaDatagrid-headerCell": {
          backgroundColor: "white",
        },
        "& .RaDatagrid-tableWrapper": {
          borderRadius: "12px",
          padding: "32px",
        },

        "& .RaDatagrid-table": {
          borderRadius: 3,
          overflow: "hidden",
          borderCollapse: "collapse",
          "& .MuiSvgIcon-fontSizeMedium ": {
            fill: "grey.300",
          },

          "& .RaDatagrid-thead": {
            height: "40px",
          },
        },

        "& .RaDatagrid-tbody tr:last-child td": {
          borderBottom: 0,
        },
        "& .RaDatagrid-expandable": {
          "& .RaDatagrid-expandIconCell": {
            "& .MuiSvgIcon-root": {
              width: "40px",
              height: "40px",
            },
          },
        },
        "& td.column-undefined": { width: "1%", whiteSpace: "nowrap" },
      }),
  },
};
