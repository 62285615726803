export const grey = {
  50: "#FAFAFA",
  100: "#F1F1F1",
  200: "#EAECEE",
  300: "#D6DADE",
  400: "#A8B0B9",
  500: "#717D8A",
  600: "#4F5B67",
  700: "#373F47",
  800: "#242D35",
  900: "#0C1116",
};
