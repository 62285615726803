import { Components } from "@react-admin/ra-enterprise";

export const RaCreate: Components["RaCreate"] = {
  styleOverrides: {
    root: {
      marginTop: "40px",
      marginLeft: "32px",
      marginRight: "32px",
      padding: "32px",
      backgroundColor: "white",
      borderRadius: 12,
      "& .MuiToolbar-root": {
        margin: "0px",
        marginLeft: "-20px",
      },
    },
  },
};
