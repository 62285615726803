import { Components, Theme } from "@mui/material";

export const MuiGrid: Components<Theme>["MuiGrid"] = {
  styleOverrides: {
    root: {
      // padding: 40,
      // margin: 0,
      // backgroundColor: "#FAFAFA",
      justifyContent: "flex-start",
      "& .MuiGrid-item": {
        height: "fit-content",
      },
    },
  },
};
