import { Components, Theme } from "@mui/material";

export const MuiButtonBase: Components<Theme>["MuiButtonBase"] = {
  styleOverrides: {
    root: {
      "& .MuiCheckbox-root": {
        color: "red",
      },
    },
  },
};
