import { Components, Theme } from "@mui/material";

export const MuiChip: Components<Theme>["MuiChip"] = {
  variants: [
    {
      props: { variant: "filled", color: "warning" },
      style: ({ theme }) =>
        theme.unstable_sx({
          color: "yellow.900",
        }),
    },
    {
      props: { variant: "filled", color: "default" },
      style: ({ theme }) =>
        theme.unstable_sx({
          backgroundColor: "grey.500",
          color: "white",
          "&.MuiButtonBase-root:hover": {
            backgroundColor: "grey.200",
            color: "grey.900",
          },
        }),
    },
  ],
};
