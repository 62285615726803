import { Components } from "@react-admin/ra-enterprise";

export const RaFileInput: Components["RaFileInput"] = {
  variants: [
    {
      props: { variant: "main" },
      style: {
        width: "350px",
      },
    },
    {
      props: { variant: "standalone" },
      style: { borderWidth: 0 },
    },
  ],
  styleOverrides: {
    root: {
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "dashed",
      // borderRadius: 12,
      height: "100%",
      width: "100%",
      overflow: "hidden",
      "& .RaLabeled-label": { display: "none" },
    },
  },
};
