import { Components, Theme } from "@mui/material";

export const MuiCard: Components<Theme>["MuiCard"] = {
  styleOverrides: {
    root: ({ theme }) =>
      theme.unstable_sx({
        backgroundColor: "background.default",
        "& .user": {
          padding: "24px",
          width: "207px",
          minHeight: "fit-content",
        },
      }),
  },
  variants: [
    {
      props: { variant: "outlined" },
      style: ({ theme }) =>
        theme.unstable_sx({
          border: "1px solid",
          borderColor: `grey.200`,
          borderRadius: "12px",
          padding: "16px",
          minHeight: "fit-content",
          display: "flex",
          justifyContent: "space-between",
        }),
    },
  ],
};
