import { Theme } from "@mui/material";
import { Components } from "@react-admin/ra-enterprise";

export const RaTabbedForm: Components["RaTabbedForm"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        backgroundColor: "grey.50",
        marginTop: 0,
        "& .MuiTabs-root": {
          backgroundColor: "white",
        },
        "& .MuiCardContent-root": {
          padding: "32px",
          backgroundColor: "white",
          margin: "32px",
          marginBottom: "0px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          "& .MuiStack-root": {
            "& >.RaDatagrid-root": {
              marginX: "-32px",
              // marginX: 0,
              marginBottom: "32px",
              "& .RaDatagrid-tableWrapper": {
                margin: "0px",
                padding: "0px",
                "& .MuiTableRow-root": {
                  border: "none",
                },
              },
            },
          },
        },
        "& .MuiToolbar-root": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          padding: "32px",
        },
        "& .MuiTableCell-root": {
          "& .MuiTypography-root": {
            // marginLeft: "16px",
          },
        },
      }),
  },
};
