import { Components, Theme } from "@mui/material";

export const MuiSvgIcon: Components<Theme>["MuiSvgIcon"] = {
  styleOverrides: {
    root: {},
    // root: sx({
    //   backgroundColor: "red",
    //   "& .addRegionIcon": { border: "2px solid red" },
    // }),
  },
};
