import { Components, Theme } from "@mui/material";

export const MuiTable: Components<Theme>["MuiTable"] = {
  styleOverrides: {
    root: {
      "& .MuiTableHead-root": {
        "& .MuiTableRow-head": {
          height: "40px",
        },
      },
    },
  },
};
