import { Components } from "@react-admin/ra-enterprise";

export const RaResettableTextField: Components["RaResettableTextField"] = {
  styleOverrides: {
    root: {
      width: "50%",
      "&.MuiFormControl-fullWidth": {
        width: "100%",
      },
    },
  },
};
