import { Components } from "@react-admin/ra-enterprise";

export const RaMarkdownInput: Components["RaMarkdownInput"] = {
  variants: [
    {
      props: { variant: "main" },
      style: {
        height: "512px",
      },
    },
    {
      props: { variant: "short" },
      style: {
        height: "250px",
      },
    },
  ],
  styleOverrides: {},
};
