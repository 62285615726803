import { Components } from "@react-admin/ra-enterprise";

export const RaRadioButtonGroupInput: Components["RaRadioButtonGroupInput"] = {
  styleOverrides: {
    // @ts-ignore
    root: ({ theme }) => {
      return theme.unstable_sx({
        "& .RaRadioButtonGroupInput-label": {
          position: "relative",
          transform: "none",
          fontSize: "16px",
          marginBottom: "12px",
          color: "grey.700",
          "&.Mui-focused": {
            color: "grey.700",
          },
        },
      });
    },
  },
};
