import { Components, Theme } from "@mui/material";
import { grey } from "~/theme/colors";

export const MuiTablePagination: Components<Theme>["MuiTablePagination"] = {
  styleOverrides: {
    root: {
      marginTop: 44,
      borderTop: "1px solid",
      borderColor: grey[200],
      color: grey[600],

      "& .MuiTablePagination-selectIcon": {
        color: grey[600],
      },
      "& .MuiPaginationItem-root": {
        color: grey[600],
      },
    },
  },
};
