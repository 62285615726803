import { Theme } from "@mui/material";
import { Components } from "@react-admin/ra-enterprise";
export const RaDeleteWithUndoButton: Components["RaDeleteWithUndoButton"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        borderRadius: "32px",
        boxShadow: "none",
        minWidth: "min-content",
        padding: "12px 20px",
        color: `white`,
        backgroundColor: "red.700",

        ":focus, :hover": {
          backgroundColor: "red.900",
        },
        ":active": {
          backgroundColor: "red.900",
        },
        ":disabled": {
          backgroundColor: "grey.50",
          color: "grey.50",
          borderColor: "transparent",
        },
      }),
  },
};
