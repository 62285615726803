export const blue = {
  50: "#F7F7FB",
  100: "#D8D8FE",
  200: "#B3B3FD",
  300: "#8484F8",
  400: "#263a96",
  500: "#4040F2",
  600: "#3333D1",
  700: "#2323BE",
  800: "#181894",
  900: "#0D0D54",
};
