import { Components, Theme } from "@mui/material";

export const MuiTabs: Components<Theme>["MuiTabs"] = {
  styleOverrides: {
    root: {
      "& .MuiButtonBase-root": {
        color: "black",
      },
      "& .MuiSelected": {
        "& .MuiButtonBase-root": {
          color: "black",
        },
      },
    },
  },
  defaultProps: {
    variant: "scrollable",
  },
};
