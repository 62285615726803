import { Theme } from "@mui/material/styles";
import { Components } from "@react-admin/ra-enterprise";

export const RaDeleteWithConfirmButton: Components["RaDeleteWithConfirmButton"] =
  {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) =>
        theme.unstable_sx({
          color: "error.contrastText",
          backgroundColor: "error.main",
          "&:hover": {
            backgroundColor: "error.dark",
          },
        }),
    },
  };
