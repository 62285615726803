import * as components from "./components";
import { ThemeOptions } from "./types";
import { typography } from "./typography";
// import MuiCalendar from "./components/MuiCalendar";
// See MuiCalendar.ts for explanation, destructuring doesn't work

export const sharedTheme: ThemeOptions = {
  components: {
    ...components,
    // ...MuiCalendar, // See notes in MuiCalendar.ts
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: "320px",
          padding: 5,
        },
      },
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          display: "flex",
          flexFlow: "row",
          alignContent: "stretch",
          justifyContent: "center",
          paddingX: "10px",
          height: "150px",
          overflow: "scroll",
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {},
        yearButton: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          height: "68px",
          margin: 0,
          padding: 0,
          "&.Mui-disabled": {
            color: "rgba(0,0,0,0.2)",
          },
        },
      },
    },
    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          display: "flex",
          flexFlow: "row wrap",
          alignContent: "stretch",
          paddingY: "10px",
          height: "300px",
          // overflow: "scroll",
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: {},
        monthButton: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          height: "50px",
          margin: 0,
          padding: 1,
          "&.Mui-disabled": {
            color: "rgba(0,0,0,0.2)",
          },
        },
      },
    },
  },
  typography,
};
