import { Components, Theme } from "@mui/material";

export const MuiOutlinedInput: Components<Theme>["MuiOutlinedInput"] = {
  defaultProps: {
    notched: false,
  },
  styleOverrides: {
    root: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
};
