import { Components, Theme } from "@mui/material";
import { grey } from "~/theme/colors";

export const MuiTableRow: Components<Theme>["MuiTableRow"] = {
  styleOverrides: {
    root: ({ theme }) =>
      theme.unstable_sx({
        height: 60,
        backgroundColor: "white",
        // borderTop: "1px solid",
        // borderColor: "grey.200",

        "& .RaDatagrid-headerCell": {
          backgroundColor: "white",
        },
        "& .MuiTableCell-head": {
          color: grey[600],
        },
      }),
  },
};
