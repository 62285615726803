import { Components } from "@react-admin/ra-enterprise";

export const RaEdit: Components["RaEdit"] = {
  styleOverrides: {
    root: {
      backgroundColor: "transparent",
      "& .RaEdit-noActions": {
        marginTop: "0px",
      },
      "& .MuiCardContent-root": {
        backgroundColor: "white",
        margin: "32px 32px 0px 32px",
        padding: "32px",
        borderRadius: "12px",
      },
      "& .RaDatagrid-tableWrapper": {
        margin: "0px",
      },
      "& .main": {
        margin: 0,
      },
      "& .RaEdit-card": {
        backgroundColor: "transparent",
      },
      "& .RaToolbar-desktopToolbar": {
        marginLeft: "32px",
        marginRight: "32px",
        marginTop: "0px",
        marginBottom: "32px",
        borderRadius: "0px 0px 16px 16px",
        padding: "32px",
      },
    },
  },
};
