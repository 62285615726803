import { Theme } from "@mui/material";
import { Components } from "@react-admin/ra-enterprise";
import { LabeledClasses } from "react-admin";

export const RaLabeled: Components["RaLabeled"] = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        position: "relative",
        transform: "none",
        [`& .${LabeledClasses.label}`]: {
          color: "grey.700",
          fontSize: "16px",
          marginBottom: 1.5,
        },
        "&.Mui-focused": {
          color: "grey.700",
        },
      }),
  },
};
