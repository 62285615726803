import { Theme } from "@mui/material";
import { Components } from "@react-admin/ra-enterprise";

export const RaMenuItemLink: Components["ItinRaMenuItemLinkSidebar"] = {
  variants: [
    {
      props: { variant: "main" },
      style: ({ theme }: { theme: Theme }) =>
        theme.unstable_sx({
          height: "48px",
          borderRight: "4px solid transparent",
          "&:hover": {
            backgroundColor: "grey.800",
          },
          "&.RaMenuItemLink-active": {
            borderRight: "4px solid",
            borderColor: "blue.400",
            "& .RaMenuItemLink-icon": {
              color: "blue.400",
            },
            backgroundColor: "grey.800",
          },
          "& .RaMenuItemLink-icon": {
            color: "grey.400",
          },
        }),
    },
  ],
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        "&+.MuiDivider-root": {
          my: 0,
        },
      }),
  },
};
