import { Theme } from "@mui/material";
import { Components } from "@react-admin/ra-enterprise";

export const RaSimpleFormIterator: Components["RaSimpleFormIterator"] = {
  variants: [
    {
      props: { variant: "carousel" },
    },
  ],
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      theme.unstable_sx({
        "& .RaSimpleFormIterator-form": {
          width: "100%",
        },
        "& .RaSimpleFormIterator-line": {
          position: "relative",
          // marginRight: "24px",
        },
        "& .RaSimpleFormIterator-action": {
          position: "absolute",
          top: 15,
          right: 15,
          "& .button-remove": {
            color: "red.700",
            size: "40px",
          },
        },
        // "& .MuiStack-root": {
        //   flexDirection: "row",
        //   width: "50%",
        //   "& .MuiFormControl-root": {
        //     width: "100%",
        //   },
        // },
      }),
  },
};
