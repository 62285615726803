import { Components, Theme } from "@mui/material";

export const MuiToolbar: Components<Theme>["MuiToolbar"] = {
  styleOverrides: {
    regular: {
      display: "flex",
      justifyContent: "space-between",
      // backgroundColor: "white",
      boxShadow: "inset 0px -1px 0px #F1F1F1",
    },
  },
};
